function Branding() {
  return (
    <div className="gist-bubble-branding">
      <div className="gist-bubble-branding-container">
        <a
          href="https://getgist.com/tours"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>Powered by Gist</div>
        </a>
      </div>
    </div>
  );
}

export default Branding;
