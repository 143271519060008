import TourFlow from './components/TourFlow';
import { useEffect, useState } from 'preact/hooks';
import { gtFormAjaxCall, removeTourFrame } from './components/utility';
import { settings } from './settings';

function App({ data }) {
  const [tour, setTour] = useState({});

  useEffect(() => {
    gtFormAjaxCall({
      method: 'GET',
      url: `${settings.ROOT_URL}projects/${data.application_id}/tours/${data.id}?random_id=${
        data.random_id
      }&trigger_type=${
        data.trigger_type
      }&resume_tour=${!!data.stepId}&preview=${data.preview}`,
      contentType: 'application/json',
    })
      .then(res => {
        if (res.completed) {
          removeTourFrame(data.id, data.application_id);
        } else {
          setTour(res);
        }
      })
      .catch(e => {
        console.error(e);
        removeTourFrame(data.id, data.application_id);
      });
  }, []);

  if (tour.tour_content) {
    return (
      <TourFlow
        tour={tour.tour_content}
        sender={tour.sender}
        tourViewDetails={tour.tour_view_details}
        data={data}
      />
    );
  }

  return '';
}

export default App;
