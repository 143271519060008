import { useEffect, useState } from 'preact/hooks';
import Branding from '../common/Branding';
import FramedComponent from '../common/FramedComponent';
import { TourContainer } from './style';
import Button from '../common/Button';

const PostBlock = ({
  tour,
  sender,
  step,
  onNextStep,
  onclose,
  clickedElement,
  tourData,
  showBranding, 
  isCloseTourClass,
    
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(
    () => {
      setTimeout(() => {
        setLoaded(true);
      }, 100);
    },
    [step],
  );
  return (
    <div
      className="post-widget"
      style={{
        visibility: `${loaded ? 'visible' : 'hidden'}`,
        width: step.width,
      }}
    >
      <div
        className={`gist-bubble-outline gist-bubble-outline--bubble-placement-bottom-left ${
          tour.show_sender ? 'post-sender' : ''
        } ${isCloseTourClass ? 'close-post' : ''}`}
      >
        {tour.show_sender && (
          <div class="gist-bubble-header">
            <span class="avatar-image">
              <img
                alt="avatar"
                src={sender.avatar_url || sender.avatar_letter}
              />
            </span>
            <p class="truncate mt-2 ml-2 mb-1">
              <span class="text-primary">{sender.full_name}</span> from{' '}
              <span class="text-muted">{tourData.project_name}</span>
            </p>
          </div>
        )}
        <FramedComponent>
          <TourContainer className="gist-bubble-container post-bubble-container">
            <div role="gist-bubble-body">
              {/*TODO: Current Step Progress*/}
              {/*<div className="gist-bubble-progress">*/}
              {/*    <div*/}
              {/*        className="gist-bubble-progress__fill"*/}
              {/*        style="width: 28.5714%;"*/}
              {/*    />*/}
              {/*</div>*/}
              <div id="gist-bubble-content" className="gist-bubble-content">
                <div dangerouslySetInnerHTML={{ __html: step.content }} />

                {step.buttons && step.buttons.length ? (
                  <div className="footer">
                    <div class="gist-bubble-buttons">
                      {step.buttons.map((button, index) => (
                        <Button
                          data={button}
                          key={`button-${index}`}
                          onNextStep={onNextStep}
                          clickedElement={clickedElement}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {showBranding && <Branding />}
          </TourContainer>
        </FramedComponent>
        {tour.show_dismiss_button && (
          <div className="gist-bubble-toolbar">
            {/*<div className="gist-bubble-draft">Preview</div>*/}
            <button
              data-testid="close-button"
              className="gist-bubble-toolbar-button"
              aria-label="Close guide"
              onTouchStart={onclose}
              onClick={onclose}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostBlock;
