import { Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Backdrop } from '../TourFlow/style';
import { getPositionAndDimensions, isElementInViewport } from '../utility';
import { useRef } from 'preact/hooks';

const BackDrop = ({ step, closeTour }) => {
  let [position, setPosition] = useState({});
  const [targetVisible, setTargetVisible] = useState(false);
  const stepRef = useRef(step);

  const init = () => {
    if (stepRef.current.show_overlay) {
      if (stepRef.current.style !== 'post') {
        try {
          const target = document.querySelector(stepRef.current.selector.css);
          if (target) {
            let targetPosition = getPositionAndDimensions(target);
            setPosition({
              top: targetPosition.top,
              left: targetPosition.left,
              height: targetPosition.height,
              width: targetPosition.width,
            });
            if (isElementInViewport(target)) {
              setTargetVisible(true);
            }
          }
        } catch (e) {
          closeTour({
            performed_action: 'error',
            current_step: stepRef.current.id,
            confetti_showed: false,
            error_message: 'Selector not found',
            selector: stepRef.current.selector.css,
            current_url: window.location.href,
          });
        }
      }
    }
  };
  useEffect(() => {
    stepRef.current = step;
    init();
  }, [step]);

  useEffect(() => {
    window.addEventListener('resize', init, { capture: true });
    window.addEventListener('scroll', init, { capture: true });
    return () => {
      window.removeEventListener('resize', init, { capture: true });
      window.removeEventListener('scroll', init, { capture: true });
    };
  }, []);

  if (step.show_overlay) {
    if (step.style === 'post') {
      return <Backdrop className={'full-screen'} />;
    }
    if (targetVisible) {
      return (
        <Fragment>
          <Backdrop
            style={{
              bottom: `calc( 100vh - ${position.top - 5}px)`,
            }}
          />
          <Backdrop
            style={{
              top: position.top + position.height + 5,
              height: `calc( 100vh - ${position.top + position.height + 5}px)`,
            }}
          />
          <Backdrop
            style={{
              top: position.top - 5,
              right: `calc( 100vw - ${position.left - 5}px)`,
              height: position.height + 10,
            }}
          />
          <Backdrop
            style={{
              top: position.top - 5,
              left: position.left + position.width + 5,
              height: position.height + 10,
            }}
          />
        </Fragment>
      );
    }
  }
  return '';
};

export default BackDrop;
