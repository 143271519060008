export const actions = {
  GO_TO_STEP: 'GO_TO_STEP',
  CLOSE_TOUR: 'CLOSE_TOUR',
  NAVIGATE: 'NAVIGATE',
  EVAL_JS: 'EVAL_JS',
};

export const beaconPosition = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  TOP_CENTER: 'top',
  LEFT_CENTER: 'left',
  RIGHT_CENTER: 'right',
  BOTTOM_CENTER: 'bottom',
  CENTER: 'center',
};
