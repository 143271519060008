import { useEffect, useState, useCallback } from 'preact/hooks';
import {
  checkButtonStatus,
  getbeaconPosition,
  getPositionAndDimensions,
} from '../utility';
import debounce from 'lodash.debounce';

const BeaconBlock = ({ beacon, closeTour, clickedElement }) => {
  const [visible, setVisibility] = useState(
    !beacon.hidden_conditions ||
    !checkButtonStatus(beacon.hidden_conditions, clickedElement),
  );
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const clickListener = e => {
      setVisibility(
        !beacon.hidden_conditions ||
        !checkButtonStatus(beacon.hidden_conditions, [
          ...clickedElement,
          e.target,
        ]),
      );
    },
    keydownListener = useCallback(
      debounce(() => {
        setVisibility(
          !beacon.hidden_conditions ||
          !checkButtonStatus(beacon.hidden_conditions, clickedElement),
        );
      }, 500),
      [],
    );

  useEffect(
    () => {
      init();
    },
    [beacon],
  );

  useEffect(() => {
    window.addEventListener('resize', init, { capture: true });
    window.addEventListener('scroll', init, { capture: true });
    window.addEventListener('click', clickListener, { capture: true });
    window.addEventListener('keydown', keydownListener, { capture: true });
    return () => {
      window.removeEventListener('resize', init, { capture: true });
      window.removeEventListener('scroll', init, { capture: true });
      window.removeEventListener('click', clickListener, { capture: true });
      window.removeEventListener('keydown', keydownListener, { capture: true });
    };
  }, []);

  const init = () => {
    if (beacon.selector.css.trim()) {
      try {
        const target = document.querySelector(beacon.selector.css);
        if (target) {
          const targetPosition = getPositionAndDimensions(target);
          const [topCords, leftCords] = getbeaconPosition(
            targetPosition,
            beacon.placement,
          );
          setTop(topCords + beacon.offsetY || 0);
          setLeft(leftCords + beacon.offsetX || 0);
          setLoaded(true);
        }
      } catch (e) {
        closeTour({
          performed_action: 'error',
          current_step: beacon.id,
          confetti_showed: false,
          error_message: 'Beacon selector not found',
          selector: beacon.selector.css,
          current_url: window.location.href,
        });
      }
    } else {
      closeTour({
        performed_action: 'error',
        current_step: beacon.id,
        confetti_showed: false,
        error_message: 'Beacon selector not found',
        selector: beacon.selector.css,
        current_url: window.location.href,
      });
    }
  };
  if (visible) {
    return (
      <div
        className="beacon-widget beacon-fixed"
        style={{
          left: `${left}px`,
          top: `${top}px`,
          visibility: `${loaded ? 'visible' : 'hidden'}`,
        }}
      >
        <div className="beacon-ring pulsing-animation" />
        <div className="beacon-ring pulsing-animation animation-delay-1000" />
      </div>
    );
  }
  return null;
};

export default BeaconBlock;
