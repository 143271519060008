import { beaconPosition } from './constants';

export const getPositionAndDimensions = node => {
  const e = node.getBoundingClientRect();
  return {
    width: e.width,
    height: e.height,
    left: e.left,
    right: e.left,
    top: e.top,
    bottom: e.top,
  };
};

export const getZoomAdjustedValue = value => {
  let zoomFactor;
  return (
    null == zoomFactor &&
    (zoomFactor = (function() {
      const computedStyle = window.getComputedStyle(window.document.body);
      if (computedStyle.zoom && '1' !== computedStyle.zoom) {
        const zoom = parseFloat(computedStyle.zoom);
        if (!isNaN(zoom)) return zoom;
      }
      return 1;
    })()),
    1 === zoomFactor ? value : value / zoomFactor
  );
};

export const getDocumentWidth = () =>
  ('BackCompat' === document.compatMode
    ? document.body
    : document.documentElement
  ).clientWidth || window.innerWidth;

export const getDocumentHeight = () =>
  ('BackCompat' === document.compatMode
    ? document.body
    : document.documentElement
  ).clientHeight || window.innerHeight;

export const getbeaconPosition = (targetPosition, beaconAlignment) => {
  switch (beaconAlignment) {
    case beaconPosition.TOP_LEFT:
      return [targetPosition.top, targetPosition.left];
    case beaconPosition.TOP_RIGHT:
      return [targetPosition.top, targetPosition.left + targetPosition.width];
    case beaconPosition.BOTTOM_LEFT:
      return [targetPosition.top + targetPosition.height, targetPosition.left];
    case beaconPosition.BOTTOM_RIGHT:
      return [
        targetPosition.top + targetPosition.height,
        targetPosition.left + targetPosition.width,
      ];
    case beaconPosition.TOP_CENTER:
      return [
        targetPosition.top,
        targetPosition.left + targetPosition.width / 2,
      ];
    case beaconPosition.RIGHT_CENTER:
      return [
        targetPosition.top + targetPosition.height / 2,
        targetPosition.left + targetPosition.width,
      ];
    case beaconPosition.BOTTOM_CENTER:
      return [
        targetPosition.top + targetPosition.height,
        targetPosition.left + targetPosition.width / 2,
      ];
    case beaconPosition.LEFT_CENTER:
      return [
        targetPosition.top + targetPosition.height / 2,
        targetPosition.left,
      ];
    case beaconPosition.CENTER:
      return [
        targetPosition.top + targetPosition.height / 2,
        targetPosition.left + targetPosition.width / 2,
      ];
    default:
      return [
        targetPosition.top + targetPosition.height / 2,
        targetPosition.left + targetPosition.width / 2,
      ];
  }
};

const notchSize = 20,
  offScreenPadding = 15;
export const getTooltipPostion = (
  targetPosition,
  tooltipPlacement,
  tooltipDimensions = {},
) => {
  let top = 0,
    left = 0;

  const tooltipWidth = tooltipDimensions.width,
    tooltipHeight = tooltipDimensions.height;

  switch (tooltipPlacement) {
    case 'ABOVE':
      top = targetPosition.top - targetPosition.height / 2;
      left = targetPosition.left + targetPosition.width / 2 - tooltipWidth / 2;
      break;
    case 'RIGHT':
      top = targetPosition.top - tooltipHeight / 2;
      left = targetPosition.left + targetPosition.width + notchSize;
      break;
    case 'BELOW':
      top = targetPosition.top + targetPosition.height + notchSize;
      left = targetPosition.left + targetPosition.width / 2 - tooltipWidth / 2;
      break;
    case 'LEFT':
      top = targetPosition.top - tooltipHeight / 2;
      left = targetPosition.left - tooltipWidth - notchSize;
      break;
  }

  const documentWidth = getZoomAdjustedValue(getDocumentWidth()),
    documentHeight = getZoomAdjustedValue(getDocumentHeight());

  const offScreenLeft = offScreenPadding,
    offScreenRight = documentWidth - tooltipWidth - 15,
    offScreenTop = offScreenPadding,
    offScreenBottom = documentHeight - tooltipHeight - 15;

  if (left < offScreenLeft) {
    left = offScreenLeft;
  } else if (left > offScreenRight) {
    left = offScreenRight;
  }

  if (top < offScreenTop) {
    top = offScreenTop;
  } else if (top > offScreenBottom) {
    top = offScreenBottom;
  }

  return [top, left];
};

export const getTooltipNotchPosition = (
  targetPosition,
  tooltipPlacement,
  tooltipPosition,
  tooltipDimensions,
) => {
  let vertical = 0,
    horizontal = 0;

  switch (tooltipPlacement) {
    case 'BELOW':
    case 'ABOVE':
      vertical = tooltipPlacement === 'ABOVE' ? '-12px' : '-20px'; // TODO: need to check tooltip height issue
      horizontal = `${targetPosition.left +
      targetPosition.width / 2 -
      tooltipPosition.left}px`;
      break;
    case 'RIGHT':
    case 'LEFT':
      vertical = `${Math.min(
        Math.max(
          targetPosition.top +
          targetPosition.height / 2 -
          tooltipPosition.top -
          notchSize / 2,
          30,
        ),
        tooltipDimensions.height - 40,
      )}px`;
      horizontal = '-20px';
      break;
  }

  return [vertical, horizontal];
};

export const getTooltipDynamicAlignment = (
  targetPosition,
  tooltipPlacement,
  tooltipDimensions = {},
) => {
  const documentWidth = getZoomAdjustedValue(getDocumentWidth()),
    documentHeight = getZoomAdjustedValue(getDocumentHeight());

  if (
    targetPosition.top +
    targetPosition.height +
    (tooltipDimensions.height || 0) +
    notchSize +
    offScreenPadding <
    documentHeight
  ) {
    return 'BELOW';
  } else if (documentHeight < targetPosition.top) {
    return 'ABOVE';
  } else if (
    targetPosition.left +
    targetPosition.width +
    (tooltipDimensions.width || 0) +
    notchSize +
    offScreenPadding <
    documentWidth
  ) {
    return 'RIGHT';
  }
  return 'LEFT';
};

export function updateTourFrequency(
  secretKey,
  frequencyType,
  applicationId,
  period = 1,
) {
  const localTourFrequency = localStorage.getItem(
      `gist_tour_frequency_${applicationId}`,
    ),
    parseItem = localTourFrequency ? JSON.parse(localTourFrequency) : [];
  if (!parseItem.some(item => item.secretKey === secretKey)) {
    let date = new Date();
    date.setTime(date.getTime() + period * 24 * 60 * 60 * 1000);
    localStorage.setItem(
      `gist_tour_frequency_${applicationId}`,
      JSON.stringify([
        ...parseItem,
        {
          secretKey,
          type: frequencyType,
          expires: date.toUTCString(),
        },
      ]),
    );
  }
}

export const openLink = (url, isNewTab) => {
  if (isNewTab) {
    window.open(url);
  } else {
    window.location.href = url;
  }
};

export const evaluateJs = code => {
  new Function(code)();
};

export const checkIsValidResponse = response =>
  response != null &&
  response !== '' &&
  response.endsWith('}') &&
  response.startsWith('{') &&
  response !== 'undefined' &&
  response !== undefined;

export const gtFormAjaxCall = params =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(params.method, params.url);
    if (params.contentType) {
      xhr.setRequestHeader('Content-Type', params.contentType);
    }
    if (params.accept) {
      xhr.setRequestHeader('accept', params.accept);
    }
    xhr.onload = function() {
      if (xhr.status === 200 || xhr.status === 204) {
        if (checkIsValidResponse(xhr.responseText)) {
          resolve(JSON.parse(xhr.responseText), xhr.status);
        } else {
          resolve(null);
        }
      } else if ([422, 404].includes(xhr.status)) {
        if (checkIsValidResponse(xhr.responseText)) {
          reject(JSON.parse(xhr.responseText));
        } else {
          reject(null);
        }
      }
    };
    if (params.skipStringify) {
      xhr.send(params.data);
    } else {
      xhr.send(JSON.stringify(params.data));
    }
  });

const checkElementCondition = ({ condition, value }, clickedElement) => {
  if (!value || !value.css) {
    return false;
  }
  let element = document.querySelector(value.css);
  if (condition === 'is_present') {
    return element;
  } else if (condition === 'is_not_present') {
    return !element;
  } else if (condition === 'is_clicked') {
    return clickedElement.some(
      ele => ele.matches(value.css) || ele.closest(value.css),
    );
  } else if (condition === 'is_not_clicked') {
    return !clickedElement.some(
      ele => ele.matches(value.css) || ele.closest(value.css),
    );
  } else {
    if (!element) {
      return false;
    } else {
      if (condition === 'is_disabled') {
        return element.disabled;
      } else if (condition === 'is_not_disabled') {
        return !element.disabled;
      }
    }
  }
  return false;
};
const checkInputCondition = ({ condition, value }) => {
  if (!value || !value.css) {
    return false;
  }
  let element = document.querySelector(value.css);
  if (!element) {
    return false;
  }
  const inputValue = element.value || '';
  if (condition === 'has_any_value') {
    return inputValue;
  } else if (condition === 'has_no_value') {
    return !inputValue;
  } else if (condition === 'equals') {
    return inputValue === value.content;
  } else if (condition === 'not_equals') {
    return inputValue !== value.content;
  } else if (condition === 'contains') {
    return inputValue.includes(value.content);
  } else if (condition === 'not_contains') {
    return !inputValue.includes(value.content);
  } else if (condition === 'starts_with') {
    const string = `^${value.content}`;
    const regexp = new RegExp(string);
    return regexp.test(inputValue);
  } else if (condition === 'does_not_starts_with') {
    const string = `^${value.content}`;
    const regexp = new RegExp(string);
    return !regexp.test(inputValue);
  } else if (condition === 'ends_with') {
    const string = `${value.content}$`;
    const regexp = new RegExp(string);
    return regexp.test(inputValue);
  } else if (condition === 'does_not_ends_with') {
    const string = `${value.content}$`;
    const regexp = new RegExp(string);
    return !regexp.test(inputValue);
  } else if (condition === 'matches_the_pattern') {
    const regexp = new RegExp(value.content);
    return regexp.test(inputValue);
  }
  return false;
};

export const checkDOMTrigger = (triggerCondition, clickedElement = []) => {
  for (let j = 0; j < triggerCondition.length; j++) {
    for (let k = 0; k < triggerCondition[j].criteria.length; k++) {
      if (triggerCondition[j].criteria[k].key === 'element') {
        if (
          checkElementCondition(triggerCondition[j].criteria[k], clickedElement)
        ) {
          triggerCondition[j]['valid'] = 'true';
          break; // Won't work on and conditions
        } else {
          triggerCondition[j]['valid'] = 'false';
        }
      } else if (triggerCondition[j].criteria[k].key === 'text_input') {
        if (checkInputCondition(triggerCondition[j].criteria[k])) {
          triggerCondition[j]['valid'] = 'true';
          break; // Won't work on and conditions
        } else {
          triggerCondition[j]['valid'] = 'false';
        }
      }
    }
  }
  let isVisible = true;
  for (let j = 0; j < triggerCondition.length; j++) {
    if (triggerCondition[j]['valid'] === 'false') {
      isVisible = false;
    }
  }
  return [isVisible, triggerCondition];
};

export const checkTrigger = trigger => {
  let commonFunctions = new _cfCommonFunctions();
  let [isReady, updatedCondition] = commonFunctions.cfCheckStaticCondition(
    trigger.conditions,
    true,
  );
  if (isReady) {
    return commonFunctions.cfCheckDynamicCondition(updatedCondition);
  }
  return [false, updatedCondition];
};

export const removeTourFrame = (id, appId) => {
  let tourView = new _gtTourView();
  localStorage.setItem(`gt-${appId}-tour`, JSON.stringify({}));
  tourView.removeTourFrame({ id });
};

export const isElementInViewport = el => {
  let rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const checkButtonStatus = (rules, clickedElement) => {
  let [isValid, updatedRule] = checkTrigger({ conditions: rules });
  if (isValid) {
    try {
      return checkDOMTrigger(updatedRule, clickedElement)[0];
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  return false;
};

export const isPreview = ({ preview }) => preview === 'true';
