import { useEffect, useRef, useState, useCallback } from 'preact/hooks';
import { actions } from '../constants';
import {
  checkDOMTrigger,
  checkTrigger,
  evaluateJs,
  gtFormAjaxCall,
  isElementInViewport,
  isPreview,
  openLink,
  removeTourFrame,
  updateTourFrequency,
} from '../utility';
import { settings } from '../../settings';
import {Button, TourCSS} from './style';
import BeaconBlock from './BeaconBlock';
import PostBlock from './PostBlock';
import TooltipBlock from './TooltipBlock';
import VideoBlock from './Video';
import BackDrop from '../common/BackDrop';
import debounce from 'lodash.debounce';
import Confetti from 'react-confetti';

let clickedElement = {};

const TourFlow = ({ tour, data: tourData, tourViewDetails, sender }) => {
  const getStepData = step_id => tour.steps.find(({ id }) => id === step_id);

  const [step, setStep] = useState(
    getStepData(tourData.stepId) || tour.steps[0],
  );
  const [isTourDone, setIsTourDone] = useState(false);

  const [listenerElement, _setListenerElement] = useState({});
  const [isCloseTourClass, setCloseTourClass] = useState(false);
  const [confettiStatus, setConfettiStatus] = useState(false);
  const listenerElementRef = useRef(listenerElement);
  const stepIdRef = useRef(step.id);

  const getLastStep = tour.steps.slice(-1)[0];

  const setListenerElement = (stepId, type, data) => {
    let actionList = [
      ...((listenerElementRef.current[stepId] &&
          listenerElementRef.current[stepId][type]) ||
        []),
      data,
    ].filter(
      (action, index, self) =>
        index === self.findIndex(({ id }) => id === action.id),
    );

    listenerElementRef.current[stepId] = {
      ...listenerElementRef.current[stepId],
      [type]: actionList,
    };
    _setListenerElement(listenerElementRef.current);
  };

  const removeTriggerListenerData = trigger => {
    listenerElementRef.current[trigger.stepId] = {
      ...listenerElementRef.current[trigger.stepId],
      trigger:
        (listenerElementRef.current[trigger.stepId] &&
          listenerElementRef.current[trigger.stepId].trigger.filter(
            ({ id }) => id !== trigger.id,
          )) ||
        [],
    };
    _setListenerElement(listenerElementRef.current);
  };

  const checkAndRunTrigger = (
    trigger,
    clickedEle = clickedElement[stepIdRef.current] || [],
  ) => {
    if (trigger && trigger.length) {
      try {
        let triggerActions = trigger.filter(
          data => checkDOMTrigger(data.condition, clickedEle)[0],
        );
        if (triggerActions.length) {
          triggerActions.map(event => {
            removeTriggerListenerData(event);
            onNextStep(event);
          });
        }
      } catch (e) {
        closeTour({
          performed_action: 'error',
          current_step: stepIdRef.current,
          confetti_showed: false,
          error_message: 'trigger failed to execute',
          current_url: window.location.href,
        });
      }
    }
  };

  const setTriggerListenerData = step => {
    if (step.triggers) {
      let availableTrigger = step.triggers.flatMap(data => {
        let [isValid, updatedCondition] = checkTrigger(data);
        if (isValid) {
          return [{ ...data, conditions: updatedCondition }];
        }
        return [];
      });

      if (availableTrigger.length) {
        availableTrigger.map(trigger => {
          setListenerElement(step.id, 'trigger', {
            stepId: step.id,
            id: trigger.id,
            condition: trigger.conditions,
            actions: trigger.actions,
            wait: trigger.wait,
          });
        });
        checkAndRunTrigger(
          availableTrigger.map(trigger => ({
            stepId: step.id,
            id: trigger.id,
            condition: trigger.conditions,
            actions: trigger.actions,
            wait: trigger.wait,
          })),
        );
      }
    }
  };

  const updateSessionStorage = (data, applicationId) => {
    localStorage.setItem(`gt-${applicationId}-tour`, JSON.stringify(data));
  };

  const setStepData = (step, updateSession = true) => {
    if (updateSession) {
      updateSessionStorage(
        {
          stepId: step.id,
          tourId: tourData.id,
          trigger_type: tourData.trigger_type,
          ...(tourData.preview ? {preview: tourData.preview} : {}),
        },
        tourData.application_id,
      );
      stepIdRef.current = step.id;
      clickedElement[step.id] = [];
    }
    setStep(step);
  };

  const closeTourMethod = data => {
    setIsTourDone(true);
    removeWindowListener();
    if (!isPreview(tourData)) {
      gtFormAjaxCall({
        method: 'POST',
        url: `${settings.ROOT_URL}projects/${tourData.application_id}/tours/${
          tourViewDetails.tour_view.id
        }/submit?random_id=${tourData.random_id}`,
        contentType: 'application/json',
        data,
      }).catch(e => console.error(e));
    }
    updateTourFrequency(
      tour.id,
      tourData.frequency_details,
      tourData.application_id,
    );
    removeWindowListener();
    removeTourFrame(tour.id, tourData.application_id);
  };

  const closeTour = data => {
    if (step.style === 'post') {
      setCloseTourClass(true);
      setTimeout(() => {
        closeTourMethod(data);
        setCloseTourClass(false);
      }, 300);
    } else {
      closeTourMethod(data);
    }
  };

  const onclose = () =>
    closeTour({
      performed_action: 'closed',
      current_step: step.id,
      confetti_showed: false,
    });

  const nextStepTriggerMethods = nextStep => {
    if (tour.show_confetti && nextStep.id === getLastStep.id) {
      fireConfetti();
    }
    if (nextStep.style === 'post') {
      setTriggerListenerData(nextStep);
      setStepData(nextStep);
    } else {
      setStepData({}, false);
      let retry = 0;
      let intervalElement = setInterval(() => {
        try {
          retry++;
          let anchorElement =
            nextStep &&
            nextStep.selector &&
            nextStep.selector.css &&
            document.querySelector(nextStep.selector.css);
          if (anchorElement && intervalElement) {
            setTimeout(() => {
              setListenerElement(nextStep.id, 'stepClick', {
                selector: nextStep.selector.css,
                actions: nextStep.actions,
                wait: nextStep.wait,
              });
              setTriggerListenerData(nextStep);
              setStepData(nextStep);
            }, retry ? 500 : 0);
            clearInterval(intervalElement);
          }
          if (retry === 10) {
            throw new Error('Element not found');
          }
        } catch (e) {
          clearInterval(intervalElement);
          closeTour({
            performed_action: 'error',
            current_step: nextStep.id,
            confetti_showed: false,
            error_message: 'Selector not found',
            selector: nextStep.selector.css,
            current_url: window.location.href,
          });
        }
      }, 500);
    }
  };

  const goToStep = nextStep => {
    let data = {
      performed_action: 'next_step',
      current_step: step.id,
      next_step: nextStep.id,
    };
    if (!isPreview(tourData)) {
      gtFormAjaxCall({
        method: 'POST',
        url: `${settings.ROOT_URL}projects/${tourData.application_id}/tours/${
          tourViewDetails.tour_view.id
        }/submit?random_id=${tourData.random_id}`,
        contentType: 'application/json',
        data,
      }).catch(e => console.error(e));
    }
    if (step.style === 'post') {
      setCloseTourClass(true);
      setTimeout(() => {
        nextStepTriggerMethods(nextStep);
        setCloseTourClass(false);
      }, 200);
    } else {
      nextStepTriggerMethods(nextStep);
    }
  };

  const keydownListener = useCallback(
    debounce(() => {
      const currentStepEvent = listenerElementRef.current[stepIdRef.current];
      if (currentStepEvent) {
        const { trigger } = currentStepEvent;
        checkAndRunTrigger(trigger);
      }
    }, 500),
    [],
  );
  const clickListener = e => {
    const { target } = e;
    clickedElement[stepIdRef.current] = [
      ...(clickedElement[stepIdRef.current] || []),
      target,
    ];
    let clickAgain = false;
    if (target.closest('a') || target.closest('[href]')) {
      if (target.dataset.gtPreventAction === 'true') {
        target.dataset.gtPreventAction = 'false';
      } else {
        clickAgain = true;
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        target.dataset.gtPreventAction = 'true';
      }
    }

    const currentStepEvent = listenerElementRef.current[stepIdRef.current];
    if (currentStepEvent) {
      const { stepClick, trigger } = currentStepEvent;
      if (stepClick && stepClick.length) {
        try {
          let eventActions = stepClick.filter(
            ({ selector }) =>
              target.matches(selector) || target.closest(selector),
          );
          if (eventActions.length) {
            eventActions.map(event => onNextStep(event));
          }
        } catch (e) {
          closeTour({
            performed_action: 'error',
            current_step: stepIdRef.current,
            confetti_showed: false,
            error_message: 'Step action failed to execute',
            current_url: window.location.href,
          });
        }
      }
      checkAndRunTrigger(trigger, clickedElement[stepIdRef.current]);
    }
    if (clickAgain) {
      target.click();
    }
  };

  const removeWindowListener = () => {
    window.removeEventListener('click', clickListener, { capture: true });
    window.removeEventListener('keydown', keydownListener, { capture: true });
  };

  const onNextStep = buttonActions => {
    buttonActions.actions.map(action => {
      switch (action.type) {
        case actions.CLOSE_TOUR: {
          if (buttonActions.wait) {
            setTimeout(() => {
              closeTour({
                performed_action: 'completed',
                current_step: step.id,
                confetti_showed: tour.show_confetti,
              });
            }, buttonActions.wait * 1000);
          } else {
            closeTour({
              performed_action: 'completed',
              current_step: step.id,
              confetti_showed: tour.show_confetti,
            });
          }

          break;
        }
        case actions.GO_TO_STEP: {
          let nextStep = getStepData(action.step_id);
          updateSessionStorage(
            {
              stepId: nextStep.id,
              tourId: tourData.id,
              trigger_type: tourData.trigger_type,
              ...(tourData.preview ? {preview: tourData.preview} : {}),
            },
            tourData.application_id,
          );
          if (buttonActions.wait) {
            setTimeout(() => {
              goToStep(nextStep);
            }, buttonActions.wait * 1000);
          } else {
            goToStep(nextStep);
          }
          break;
        }
        case actions.EVAL_JS: {
          if (buttonActions.wait) {
            setTimeout(() => {
              evaluateJs(action.code);
            }, buttonActions.wait * 1000);
          } else {
            evaluateJs(action.code);
          }
          break;
        }
        case actions.NAVIGATE: {
          if (buttonActions.wait) {
            setTimeout(() => {
              openLink(action.url, action.navigate_target_new_tab);
            }, buttonActions.wait * 1000);
          } else {
            openLink(action.url, action.navigate_target_new_tab);
          }
          break;
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('click', clickListener, { capture: true });
    window.addEventListener('keydown', keydownListener, { capture: true });
    if (step.style !== 'post') {
      setListenerElement(step.id, 'stepClick', {
        selector: step.selector.css,
        actions: step.actions,
        wait: step.wait,
      });
    }
    setTriggerListenerData(step);
    return () => removeWindowListener();
  }, []);

  const endPreview = () => {
    localStorage.removeItem(`gt-${tourData.application_id}-tour`);
    removeTourFrame(tour.id, tourData.application_id);
  }

  const fireConfetti = () => {
    setConfettiStatus(true);
  };

  if (!isTourDone && step)
    return (
      <TourCSS>
        <div className="tour-ui">
          {step.beacons &&
            step.beacons.length ? (
              step.beacons.map(data => (
                <BeaconBlock
                  beacon={data}
                  closeTour={closeTour}
                  clickedElement={clickedElement[stepIdRef.current] || []}
                />
              ))) : null}
          {step.style === 'post' && (
            <PostBlock
              tour={tour}
              sender={sender}
              step={step}
              onNextStep={onNextStep}
              onclose={onclose}
              clickedElement={clickedElement[stepIdRef.current] || []}
              tourData={tourData}
              showBranding={tour.show_branding}
              isCloseTourClass={isCloseTourClass}
            />
          )}
          {step.style === 'pointer' && (
            <TooltipBlock
              tour={tour}
              step={step}
              onNextStep={onNextStep}
              onclose={onclose}
              clickedElement={clickedElement[stepIdRef.current] || []}
              showBranding={tour.show_branding}
            />
          )}
          {step.style === 'video' && (
            <VideoBlock
              tour={tour}
              step={step}
              onNextStep={onNextStep}
              onclose={onclose}
              clickedElement={clickedElement[stepIdRef.current] || []}
              showBranding={tour.show_branding}
            />
          )}
        </div>
        <BackDrop step={step} closeTour={closeTour} />
        {confettiStatus && (
          <Confetti
            recycle={false}
            numberOfPieces={200}
            gravity={0.5}
            width={window.innerWidth}
            height={window.innerHeight}
            onConfettiComplete={() => setConfettiStatus(false)}
          />
        )}
        {tourData.preview && (
          <Button
            className={'end-preview-btn'}
            primary={true}
            onClick={endPreview}
          >
            End preview
          </Button>
        )}
      </TourCSS>
    );
};

export default TourFlow;
