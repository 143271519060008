import { checkButtonStatus } from '../utility';
import { Button } from '../TourFlow/style';
import debounce from 'lodash.debounce';
import { useState, useCallback, useEffect } from 'preact/hooks';

function ButtonComponent({ data, onNextStep, clickedElement }) {
  const [visible, setVisibility] = useState(
    !data.hidden_conditions ||
    !checkButtonStatus(data.hidden_conditions, clickedElement),
  );
  const [disabled, setDisabled] = useState(
    data.disabled_conditions &&
    checkButtonStatus(data.disabled_conditions, clickedElement),
  );
  const clickListener = e => {
      setVisibility(
        !data.hidden_conditions ||
        !checkButtonStatus(data.hidden_conditions, [
          ...clickedElement,
          e.target,
        ]),
      );
      setDisabled(
        data.disabled_conditions &&
        checkButtonStatus(data.disabled_conditions, [
          ...clickedElement,
          e.target,
        ]),
      );
    },
    keydownListener = useCallback(
      debounce(() => {
        setVisibility(
          !data.hidden_conditions ||
          !checkButtonStatus(data.hidden_conditions, clickedElement),
        );
        setDisabled(
          data.disabled_conditions &&
          checkButtonStatus(data.disabled_conditions, clickedElement),
        );
      }, 500),
      [],
    );

  useEffect(() => {
    window.addEventListener('click', clickListener, { capture: true });
    window.addEventListener('keydown', keydownListener, { capture: true });
    return () => {
      window.removeEventListener('click', clickListener, { capture: true });
      window.removeEventListener('keydown', keydownListener, { capture: true });
    };
  }, []);

  if (visible) {
    return (
      <Button
        primary={data.style === 'primary'}
        onTouchStart={() => onNextStep(data)}
        onClick={() => onNextStep(data)}
        disabled={disabled}
      >
        {data.text}
      </Button>
    );
  }
  return null;
}

export default ButtonComponent;
