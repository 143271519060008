import { StyleSheetManager } from 'styled-components';
import { createPortal } from 'preact/compat';
import { useEffect, useState, useRef } from 'preact/hooks';
import { GlobalStyle } from '../TourFlow/style';
import {getPositionAndDimensions} from '../utility';

let observer = null;

const FramedComponent = ({ children, onFrameLoad, ...rest }) => {
  const [height, setHeight] = useState(1);
  const frameRef = useRef(null);

  const handleResize = () => {
    if (frameRef.current) {
      const frameWindow = frameRef.current.contentWindow;
      const height = getHeight();
      setHeight(height);
      if (onFrameLoad && height) onFrameLoad(frameWindow);
    }
  };

  const getHeight = () => {
    const frameWindow = frameRef.current.contentWindow;
    let getContentElement = frameWindow.document.body.querySelector(
      '.gist-bubble-container',
    );
    if(getContentElement) {
      return Math.ceil(getPositionAndDimensions(getContentElement).height);
    }
    return 0;
  }

  useEffect(() => {
    if (frameRef.current) {
      const frameWindow = frameRef.current.contentWindow;
      let getContentElement = frameWindow.document.body;
      setHeight(Math.ceil(getPositionAndDimensions(getContentElement).height));
      observer = new ResizeObserver(handleResize);
      setTimeout(()=>{
        observer.observe(getContentElement.querySelector(
          '.gist-bubble-content',
        ));
      }, 0);
    }
    return () => {
      if (observer) observer.disconnect();
    };
  }, []);

  return (
    <iframe
      ref={frameRef}
      id={'containerFrame'}
      title={'tour container iframe'}
      width={'100%'}
      style={{
        ...(rest.style || {}),
        height: height,
        maxHeight: 'calc(100vh - 100px)',
      }}
      {...rest}
    >
      {frameRef.current &&
        createPortal(
          <StyleSheetManager
            target={frameRef.current.contentWindow.document.head}
          >
            <div>
              <GlobalStyle />
              {children}
            </div>
          </StyleSheetManager>,
          frameRef.current.contentWindow.document.body,
        )}
    </iframe>
  );
};

export default FramedComponent;
