import { useEffect, useRef, useState } from 'preact/hooks';
import {
  getDocumentHeight,
  getPositionAndDimensions,
  getTooltipDynamicAlignment,
  getTooltipNotchPosition,
  getTooltipPostion,
  getZoomAdjustedValue,
  isElementInViewport,
} from '../utility';
import Branding from '../common/Branding';
import { TourContainer } from './style';
import FramedComponent from '../common/FramedComponent';
import Button from '../common/Button';

const TooltipBlock = props => {
  const {
    step,
    onNextStep,
    onclose,
    clickedElement,
    showBranding,
    tour,
  } = props;
  const [height, setHeight] = useState();
  const [top, setTop] = useState();
  const [left, setLeft] = useState();
  const [notchVertical, setNotchVertical] = useState();
  const [notchHorizontal, setNotchHorizontal] = useState();
  const [adjustedAlignment, setAdjustedAlignment] = useState(step.placement);
  const [loaded, setLoaded] = useState(false);
  const [targetVisible, setTargetVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', init, { capture: true });
    window.addEventListener('scroll', init, { capture: true });
    return () => {
      window.removeEventListener('resize', init, { capture: true });
      window.removeEventListener('scroll', init, { capture: true });
    };
  }, []);

  useEffect(
    () => {
      setTimeout(() => init({ onload: true }), 100);
    },
    [step],
  );

  const init = ({ onload }) => {
    try {
      const target = document.querySelector(step.selector.css);
      if (target) {
        let tooltipDimensions = {
          width: ref.current ? ref.current.offsetWidth : 0,
          height: ref.current ? ref.current.offsetHeight : 0,
        };

        const tooltipPlacement = step.tooltip_placement,
          targetPosition = getPositionAndDimensions(target);

        const dynamicAlignment = getTooltipDynamicAlignment(
          targetPosition,
          tooltipPlacement,
          tooltipDimensions,
        );
        if (!step.placement) {
          setAdjustedAlignment(dynamicAlignment);
        }
        const [topCords, leftCords] = getTooltipPostion(
          targetPosition,
          adjustedAlignment || dynamicAlignment,
          tooltipDimensions,
        );
        setTop(topCords);
        setLeft(leftCords);

        const tootipPosition = {
          top: topCords,
          left: leftCords,
        };

        const documentHeight = getZoomAdjustedValue(getDocumentHeight());
        let bubble = ref.current;
        setHeight(Math.min(documentHeight - 30, bubble.scrollHeight));

        const [
          notchVerticalCords,
          notchHorizontalCords,
        ] = getTooltipNotchPosition(
          targetPosition,
          adjustedAlignment || dynamicAlignment,
          tootipPosition,
          tooltipDimensions,
        );

        setNotchVertical(notchVerticalCords);
        setNotchHorizontal(notchHorizontalCords);
      }
      if (isElementInViewport(target)) {
        setTargetVisible(true);
      } else if (onload) {
        target.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getNotchCSS = () => {
    switch (adjustedAlignment) {
      case 'ABOVE':
        return `bottom: ${notchVertical} !important; left: ${notchHorizontal} !important;`;
      case 'BELOW':
        return `top: ${notchVertical} !important; left: ${notchHorizontal} !important;`;
      case 'RIGHT':
        return `top: ${notchVertical} !important; left: ${notchHorizontal} !important;`;
      case 'LEFT':
        return `top: ${notchVertical} !important; right: ${notchHorizontal} !important;`;
    }
    return '';
  };

  return (
    <div
      className="tooltip-widget"
      style={{
        width: step.width,
        transform: `translate3d(${left}px, ${top}px, 0px)`,
        visibility: `${loaded && targetVisible ? 'visible' : 'hidden'}`,
      }}
    >
      <div
        className="gist-bubble-outline gist-bubble-outline--bubble-placement-bottom-left"
        ref={ref}
      >
        <FramedComponent onFrameLoad={() => setLoaded(true)}>
          <TourContainer className="gist-bubble-container">
            <div role="gist-bubble-body">
              {/*TODO: Current Step Progress*/}
              {/*<div className="gist-bubble-progress">*/}
              {/*    <div*/}
              {/*        className="gist-bubble-progress__fill"*/}
              {/*        style="width: 28.5714%;"*/}
              {/*    />*/}
              {/*</div>*/}
              <div id="gist-bubble-content" className="gist-bubble-content">
                <div dangerouslySetInnerHTML={{ __html: step.content }} />

                {step.buttons && step.buttons.length ? (
                  <div className="footer">
                    <div className="gist-bubble-buttons">
                      {step.buttons.map((button, index) => (
                        <Button
                          data={button}
                          key={`button-${index}`}
                          onNextStep={onNextStep}
                          clickedElement={clickedElement}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>

              {tour.show_dismiss_button && (
                <div className="gist-bubble-toolbar">
                  {/*<div className="gist-bubble-draft">Preview</div>*/}
                  <button
                    data-testid="close-button"
                    className="gist-bubble-toolbar-button"
                    aria-label="Close guide"
                    onClick={onclose}
                    onTouchStart={onclose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" />
                    </svg>
                  </button>
                </div>
              )}
            </div>

            {showBranding && <Branding />}
          </TourContainer>
        </FramedComponent>
      </div>
      <div
        className={`gist-bubble__tooltip-notch gist-bubble__tooltip-notch--${adjustedAlignment &&
        adjustedAlignment.toLowerCase()}`}
        style={getNotchCSS()}
      />
    </div>
  );
};

export default TooltipBlock;
