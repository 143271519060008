import styled, { css } from 'styled-components';

const reset = css`
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: inherit;
    vertical-align: baseline;
  }
`;

export const Backdrop = styled.div`
    z-index: 2147483100;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    visibility: visible;
  &.full-screen {
    position: fixed;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 14px;
  display: inline-flex;
  font-weight: 600;
  height: auto;
  min-width: 0;
  white-space: normal;
  border: 1px solid #1872ff;
  color: #1872ff;
  background: #fff;
  padding: 6px 18px;
  border-radius: 4px;

  ${props => props.primary && css`
    background-color: #1872ff;
    color: #ffffff;
    &:hover {
      opacity: 0.9;
    }
  `}
  ${props => props.disabled && css`
    cursor: not-allowed;
  `}
  
`

import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`

export const TourContainer = styled.div`
  ${reset} 
  &.gist-bubble-container {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border-width: 0;
    border-style: solid;
    border-color: #0f172a;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    padding: 24px;
  }

    .gist-bubble-progress {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 2px;
      overflow: hidden;

      .gist-bubble-progress__fill {
        width: 0;
        height: 100%;
        background-color: #2563eb;
        transition: width 200ms cubic-bezier(0.25, 0.8, 0.5, 1);
        border-radius: 1px;
      }
    }

    .gist-bubble-content {
      white-space: pre-wrap;
      overflow-wrap: break-word;

      h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 0.5em;
        color: rgba(0,0,0,.85);
        font-weight: 500;
      }

      p,
      h1,
      h2,
      blockquote,
      pre,
      ol,
      ul {
        margin-top: 0;
        min-height: 24px;
        flex-shrink: 0;
      }

      h1 {
        font-size: 24px;
        line-height: 1.33;
        font-weight: 400;
        color: #182c40;
      }

      h2 {
        font-size: 21px;
        line-height: 1.5em;
        color: #182c40;
      }

      p {
        margin-top: 0;
        margin-bottom: 1em;
        font-size: 14px;
        line-height: 1.5;
        color: #000;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        color: #1890ff;
        text-decoration: none;
        background-color: initial;
        outline: none;
        cursor: pointer;
        -webkit-transition: color .3s;
        transition: color .3s;
      }
      ul,
      ol {
        list-style: none;
        margin-left: 1.5rem;

        li {
          line-height: 1.5;
        }
      }

      ul li {
        list-style-type: disc;
      }

      ol li {
        list-style-type: decimal;
      }
      img {
        width: 100%;
        height: auto;
      }
      iframe {
        width: 100% !important;
      }

      .text-align-center {
        text-align: center;
      }

      .text-align-end {
        text-align: right;
      }
      
      div p:last-child {
        margin-bottom: 0;
      }

      .footer {
        margin-top: 1rem;

        .gist-bubble-buttons {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          align-items: start;
          flex-shrink: 0;
          gap: 8px;
        }
      }
    }

    .gist-bubble-toolbar {
      z-index: 100;
      position: fixed;
      top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 1.5rem;
      right: 0.5rem;

      .gist-bubble-draft {
        font-family: "Helvetica Neue", Helvetica, sans-serif;
        font-size: 0.625rem;
        font-weight: 600;
        height: 1rem;
        line-height: 1rem;
        padding: 0 0.125rem;
        color: rgba(15, 23, 42, 0.5);
        background-color: rgba(255, 255, 255, 1);
        border-radius: 0.25rem;
        text-transform: uppercase;
      }

      .gist-bubble-toolbar-button {
        cursor: pointer;
        outline: 0;
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
      }

      .gist-bubble-toolbar-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
        width: 1.5rem;
        height: 1.5rem;
        color: #0f172a;
        background: transparent;
        padding: 0;
        border-radius: 0.25rem;
        cursor: pointer;

        &:not(:disabled) {
          cursor: pointer;
        }

        &:hover {
          background-color: #e7eefd !important;
        }

        & > svg {
          width: 16px;
          height: 1em;
          margin: 0!important;
        }
      }
    }

    .gist-bubble-branding {
      //TODO Height 
      //height: 1rem;

      .gist-bubble-branding-container {
        position: absolute;
        bottom: 0.5rem;
        display: flex;
        font-size: 12px;
        left: 0.75rem;

        a {
          display: flex;
          align-items: center;
          font-weight: 500;
          text-decoration: none;
          color: #0f172a;
          opacity: 0.5;

          svg {
            display: block;
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }
        }
      }
    }
  
  &.post-bubble-container {
    padding: 24px 40px;
  }
  &.video-container {
    padding: 0;
    .footer {
      margin: 0;
      padding: 1rem;
    }
    }
`;

/*Beacon*/
export const TourCSS = styled.div`
  ${reset}
  .beacon-widget {
    width: 16px;
    height: 16px;
    background-color: #facc15;
    border-radius: 100%;

    &.beacon-fixed {
      position: fixed;
      top: 0;
      left: 0;
      will-change: transform;
      z-index: 2147483102;
      transform: translate(-50%, -50%);
    }

    .beacon-ring {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #facc15;
      border-radius: 100%;

      &.pulsing-animation {
        opacity: 0;
        animation: gist-beacon-ring-transform-effect 2000ms ease-out infinite,
          gist-beacon-ring-opacity-effect 2000ms linear infinite;
      }

      &.animation-delay-1000 {
        animation-delay: 1000ms;
      }
    }
  }
  .end-preview-btn {
    position: fixed;
    z-index: 2147483102 !important;
    top: 20px;
    right: 20px;
  }
  
  canvas {
    z-index: 2147483102 !important;
  }

  @keyframes gist-beacon-ring-transform-effect {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(3);
    }
  }

  @keyframes gist-beacon-ring-opacity-effect {
    0% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes gist-bubble-outline-effect {
    0% {
      scale: 0;
      opacity: 0;
    }
    100% {
      scale: 1;
      opacity: 1;
    }
  }


  @keyframes gist-post-close-effect {
    0% {
      scale: 1;
      opacity: 1;
    }
    100% {
      scale: 0;
      opacity: 0;
    }
  }

  .tooltip-widget, .post-widget {
    //filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.15))
    //  drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
    //box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
    z-index: 2147483101;
    opacity: 1;
    transition: opacity 250ms linear 0s, opacity 500ms cubic-bezier(0.25, 0.8, 0.5, 1) 0s;
    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.14));
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    max-width: 100vw;

    .gist-bubble-outline {
      position: relative;
      overflow-y: auto;
      border-radius: 8px;

      .gist-bubble__avatar-notch {
        position: absolute;
        height: 0;
        width: 0;
        border: 20px solid transparent;
      }
    }

    .gist-bubble__tooltip-notch {
      pointer-events: none;
      position: absolute;
      height: 0;
      width: 0;
      border: 10px solid transparent;

      &.gist-bubble__tooltip-notch--below {
        border-bottom-color: #ffffff;
      }
      &.gist-bubble__tooltip-notch--left {
        border-left-color: #ffffff;
      }
      &.gist-bubble__tooltip-notch--above {
        border-top-color: #ffffff;
      }
      &.gist-bubble__tooltip-notch--right {
        border-right-color: #ffffff;
      }
    }
  }

  .tooltip-widget {
    position: fixed;
    top: 0;
    left: 0;
  }

  .post-widget {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .gist-bubble-outline {
      animation: gist-bubble-outline-effect 300ms ease 0ms;
    }
    .close-post {
      animation: gist-post-close-effect 300ms ease 0ms;
    }
    
    .post-sender {
      background: #fff;
      overflow: visible;
      margin-top: 20px;
      .gist-bubble-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        width: 100%;
        margin-top: -20px;
        right: 0;

        .avatar-image {
          overflow: hidden;
          width: 32px;
          height: 32px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .truncate {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 8px 0 4px 8px;

          .text-primary {
            color: #1872ff;
          }

          .text-muted {
            color: #707070;
          }
        }
      }
      .gist-bubble-toolbar {
        top: 1.7rem;
      }
    }
    .gist-bubble-toolbar {
      z-index: 100;
      position: fixed;
      top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 1.5rem;
      right: 0.5rem;

      .gist-bubble-draft {
        font-family: "Helvetica Neue", Helvetica, sans-serif;
        font-size: 0.625rem;
        font-weight: 600;
        height: 1rem;
        line-height: 1rem;
        padding: 0 0.125rem;
        color: rgba(15, 23, 42, 0.5);
        background-color: rgba(255, 255, 255, 1);
        border-radius: 0.25rem;
        text-transform: uppercase;
      }

      .gist-bubble-toolbar-button {
        cursor: pointer;
        outline: 0;
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
      }

      .gist-bubble-toolbar-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
        width: 1.5rem;
        height: 1.5rem;
        color: #0f172a;
        background: transparent;
        padding: 0;
        border-radius: 0.25rem;
        cursor: pointer;

        &:not(:disabled) {
          cursor: pointer;
        }

        &:hover {
          background-color: #e7eefd !important;
        }

        & > svg {
          width: 16px;
          height: 1em;
          margin: 0!important;
        }
      }
    }
  }
`;
